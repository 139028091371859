import * as React from "react"
// import { Link } from "gatsby"

import ContactFormComponent from '../components/contactForm'

const ContactPage = () => {
    return (
        <main className={'bg-red-50 w-screen h-screen m-0 p-0 flex items-center justify-center flex-col'}>
            <title>Einfach unvergessliche Trauungen. Einzigartig, wie eure Geschichte.</title>
            <h1 className={'text-2xl font-bold mb-5'}>Einfach unvergessliche Trauungen. Einzigartig, wie eure Geschichte.</h1>
            <p className={'mb-8'}>Jetzt unverbindliche Kontaktanfrage senden – ich freue mich bereits auf eure Nachricht!</p>
            {/* <ContactFormComponent /> */}
            <h2 className={'my-8'}>Meine Kontaktdetails:</h2>
            <div className={'grid grid-cols-1 gap-y-6 sm:grid-cols-2'}>
                <ul>
                    <li>Livia Sinz</li>
                    <li>Freie Traurednerin</li>
                    <li>Marktgasse 14</li>
                    <li>9450 Altstätten</li>
                </ul>
                <ul>
                    <li>
                        E-Mail: <a href='mailto:mail@livia-traut.ch'>mail@livia-traut.ch</a>{' '}
                    </li>
                    <li>
                        Tel: <a href='tel:0041793765821'>079 376 58 21</a>
                    </li>
                    <li>
                        Whatsapp:{' '}
                        <a href={'https://api.whatsapp.com/send?phone=41793765821&text=Hoi%20Livia!'} target={'_blank'} rel={'noopener noreferrer'}>
                            Chat starten
                        </a>
                    </li>
                    <li>
                        Instagram:{' '}
                        <a href={'https://www.instagram.com/liviatraut/?hl=de'} target={'_blank'} rel={'noopener noreferrer'}>
                            @liviatraut
                        </a>
                    </li>
                </ul>
            </div>
        </main>
    )
}

export default ContactPage
